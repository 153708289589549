import React, { useEffect } from "react";

import ForgotPasswordForm from "../components/forgotPassword"
import ResetPasswordForm from "../components/resetPassword";
import Box from "@mui/material/Box";
import { useStyles } from "../styles/index.styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../../common/theme";
import background from "../../assets/images/bg-mobile.png";
import backgroundDesktop from "../../assets/images/background.png";
import { useParams } from 'react-router-dom';

export default function ForgotPassword() {
  const classes = useStyles();
  const token = secureLocalStorage.getItem("token");
  const navigate = useNavigate();
  const { tokenForgot } = useParams();

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        className={classes.containerPageLogin}
        sx={{ backgroundImage: { lg: `url(${backgroundDesktop})` } }}
      >
        <ToastContainer position="bottom-right" newestOnTop theme="colored" />
        {!tokenForgot ? <ForgotPasswordForm /> : <ResetPasswordForm />}

      </Box>
    </ThemeProvider>
  );
}
