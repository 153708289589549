import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import LoginService from "../../../services/LoginService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useStyles } from "../../styles/index.styles";
import { Alert, Divider } from "@mui/material";

export default function resetPassword() {
  const navigate = useNavigate();
  const [versionBackend, setVersionBackend] = useState("");
  const versionFrontend = process.env.REACT_APP_VERSION;
  const [isDisabled, setIsDisabled] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  const [severity, setSeverity] = useState("");

  const classes = useStyles();
  const { tokenForgot } = useParams();

  const { t } = useTranslation();

  const [new_password, setNewPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email")

    if (new_password == '') {
        toast(t('toast_msg_formpass_newpassword'), {
          type: 'error',
        })
        return
      }

      if (confirm_password == '') {
        toast(t('toast_msg_formpass_confirmpassword'), {
          type: 'error',
        })
        return
      }

      if (new_password != confirm_password) {
        toast(t('toast_msg_formpass_confirmpassworddiff'), {
          type: 'error',
        })
        return
      }

    setIsDisabled(true);
    setSeverity("info")
    setStatusMsg("Processando solicitação, aguarde!")

    LoginService.forgotPassword({
        "email": email,
        "tokenForgot": tokenForgot,
        "password": new_password,
        "password_confirmation": confirm_password,
      }).then((response: any) => {

        toast(response?.data?.Msg, {
          type: response.status == 200 ? "success" : "error",
          position: 'top-left'
        });

        if(response.status != 200)
          {
            setIsDisabled(false);
            setStatusMsg("Não foi possível alterar sua senha!")
            setSeverity("error")
            document.getElementById('alertType')?.setAttribute('severity', severity)
          }

          if(response.status == 200)
          {
            setIsDisabled(true);
            setStatusMsg("Redirecionaremos para tela de login em alguns instantes...")
            setSeverity("info")
            document.getElementById('alertType')?.setAttribute('severity', severity)
            const interval = setInterval(() => {
              navigate('/login')
            }, 4500);
          }

      })
      .catch((e: any) => {

        toast(e?.data?.Msg, {
          type: "error",
        });
      });
  };

  const messagesAlert = () => {
      switch(severity) {
        case 'info':
          return (<Alert id='alertType' severity='info'>{statusMsg}</Alert>);
        case 'error':
          return (<Alert id='alertType' severity='error'>{statusMsg}</Alert>);
        case 'success':
          return (<Alert id='alertType' severity='success'>{statusMsg}</Alert>);
        default:
          return ('');
      }
    }

  const getVersion = () => {
    LoginService.version()
      .then((response: any) => {
        setVersionBackend(response.data.version);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <Box
      className={classes.containerFormLogin}
      sx={{
        width: { lg: "30%" },
        backgroundColor: { lg: "#FFFFFF" },
        boxShadow: { lg: "0px 3px 20px rgba(65, 56, 96, 0.7)" },
        justifyContent: { lg: "center" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "40px 30px", lg: 10 },
          textAlign: "center",
        }}
      >
        <Box className={classes.logo}>
          <img src={"/logo.svg"} style={{ width: 200 }} />
        </Box>

        <Divider className={classes.divider} />

        <Typography
          component="h1"
          variant="h5"
          className={classes.titleWelcome}
        >
          {t('label_link_reset_password')}
        </Typography>
        <Typography className={classes.subtitleWelcome}>
          {t("text_reset_password")}
        </Typography>
        {
          messagesAlert()
        }
        <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="new_password"
              type="password"
              name="new_password"
              label={t('input_label_new_password')}
              variant="outlined"
              value={new_password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(event.target.value)
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirm_password"
              type="password"
              name="confirm_password"
              label={t('input_label_confirm_password')}
              variant="outlined"
              value={confirm_password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value)
              }}
            />
          {/* <TextField
            required
            label={'token'}
            name="tokenForgot"
            autoComplete="tokenForgot"
            //variant="outlined"
            value={tokenForgot}
            hidden
            disabled
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isDisabled}
          >
            {t("button_reset_password")}
          </Button>
        </Box>
        <Box component="span" m={2}>
          <Typography className={classes.versionText}>
            {t("title_description_version_frontend")} {versionFrontend}
          </Typography>
          <Typography className={classes.versionText}>
            {t("title_description_version_backend")} {versionBackend}
          </Typography>
        </Box>

      </Box>
    </Box>
  );
}
