import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import LoginService from "../../../services/LoginService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { useState, useEffect } from "react";
import { useStyles } from "../../styles/index.styles";
import logo from "../../../assets/images/logo_login.png";
import { Alert, Divider } from "@mui/material";


export default function forgotPassword() {
  const navigate = useNavigate();
  const [versionBackend, setVersionBackend] = useState("");
  const versionFrontend = process.env.REACT_APP_VERSION;
  const [isDisabled, setIsDisabled] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  const [severity, setSeverity] = useState("");
  const classes = useStyles();

  const { t } = useTranslation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email")

    if (email == "") {
      toast(t("toast_msg_auth_email"), {
        type: "error",
      });
      return;
    }

    setIsDisabled(true);
    setSeverity("info")
    setStatusMsg("Processando solicitação, aguarde!")

    LoginService.forgotPassword({"email": email})
      .then((response: any) => {

        toast(response?.data?.Msg, {
          type: response.status == 200 ? "success" : "error",
          position: 'top-left'
        });

        if(response.status != 200)
        {
          setIsDisabled(false);
          setStatusMsg("Usuário não encontrado!")
          setSeverity("error")
          document.getElementById('alertType')?.setAttribute('severity', severity)
        }

        if(response.status == 200)
        {
          setIsDisabled(true);
          setStatusMsg(response?.data?.Msg)
          setSeverity("success")
          document.getElementById('alertType')?.setAttribute('severity', severity)
        }

      })
      .catch((e: any) => {

        toast(e?.data?.Msg, {
          type: "error",
        });
      });
  };

  const getVersion = () => {
    LoginService.version()
      .then((response: any) => {
        setVersionBackend(response.data.version);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getVersion();
  }, []);

  const messagesAlert = () => {
    switch(severity) {
      case 'info':
        return (<Alert id='alertType' severity='info'>{statusMsg}</Alert>);
      case 'error':
        return (<Alert id='alertType' severity='error'>{statusMsg}</Alert>);
      case 'success':
        return (<Alert id='alertType' severity='success'>{statusMsg}</Alert>);
      default:
        return ('');
    }
  }

  return (
    <Box
      className={classes.containerFormLogin}
      sx={{
        width: { lg: "30%" },
        backgroundColor: { lg: "#FFFFFF" },
        boxShadow: { lg: "0px 3px 20px rgba(65, 56, 96, 0.7)" },
        justifyContent: { lg: "center" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "40px 30px", lg: 10 },
          textAlign: "center",
        }}
      >
        <Box className={classes.logo}>
          <img src={"/logo.svg"} style={{ width: 200 }} />
        </Box>

        <Divider className={classes.divider} />

        <Typography
          component="h1"
          variant="h5"
          className={classes.titleWelcome}
        >
          {t('label_link_forgot_password')}
        </Typography>

        <Typography className={classes.subtitleWelcome}>
          {t("text_forgot_password")}
        </Typography>
        {
          messagesAlert()
        }
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("form_label_email")}
            name="email"
            autoComplete="email"
            autoFocus
            variant="outlined"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isDisabled}
          >
            {t("button_forgot_password")}
          </Button>
        </Box>
        <Box component="span" m={2}>
          <Typography className={classes.versionText}>
            {t("title_description_version_frontend")} {versionFrontend}
          </Typography>
          <Typography className={classes.versionText}>
            {t("title_description_version_backend")} {versionBackend}
          </Typography>
        </Box>

      </Box>
    </Box>
  );
}
