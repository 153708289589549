import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import LoginService from "../../../services/LoginService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { useState, useEffect } from "react";
import { useStyles } from "../../styles/index.styles";
import logo from "../../../assets/images/logo_login.png";
import { Divider } from "@mui/material";

export default function SignIn() {
  const navigate = useNavigate();
  const [versionBackend, setVersionBackend] = useState("");
  const versionFrontend = process.env.REACT_APP_VERSION;

  const classes = useStyles();

  const { t } = useTranslation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get("email") == "") {
      toast(t("toast_msg_auth_email"), {
        type: "error",
      });
      return;
    }

    if (data.get("password") == "") {
      toast(t("toast_msg_auth_password"), {
        type: "error",
      });
      return;
    }

    secureLocalStorage.removeItem("dt_start_session");

    const dataLogin = {
      email: data.get("email"),
      password: data.get("password"),
    };
    LoginService.login(dataLogin)
      .then((response: any) => {
        secureLocalStorage.setItem("token", response.data.access_token);
        secureLocalStorage.setItem(
          "permissions",
          response.data.permissions.join(",")
        );

        secureLocalStorage.setItem("name", response.data.profile.name);
        secureLocalStorage.setItem("entity", response.data.profile.entity_id);
        secureLocalStorage.setItem("id", response.data.profile.id);
        secureLocalStorage.setItem("role", response.data.role[0]);
        secureLocalStorage.setItem("avatar_url", response.data.profile.avatar);
        secureLocalStorage.setItem("expires_in", response.data.expires_in);
        secureLocalStorage.setItem("dt_start_session", new Date());
        localStorage.setItem("menu", "1");

        navigate("/home");
      })
      .catch((e: any) => {
        toast(t("invalid_credencials"), {
          type: "error",
        });
      });
  };

  const getVersion = () => {
    LoginService.version()
      .then((response: any) => {
        setVersionBackend(response.data.version);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <Box
      className={classes.containerFormLogin}
      sx={{
        width: { lg: "30%" },
        backgroundColor: { lg: "#FFFFFF" },
        boxShadow: { lg: "0px 3px 20px rgba(65, 56, 96, 0.7)" },
        justifyContent: { lg: "center" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "40px 30px", lg: 10 },
          textAlign: "center",
        }}
      >
        <Box className={classes.logo}>
          <img src={"/logo.svg"} style={{ width: 200 }} />
        </Box>

        <Divider className={classes.divider} />

        <Typography
          component="h1"
          variant="h5"
          className={classes.titleWelcome}
        >
          {t("welcome")}
        </Typography>

        <Typography className={classes.subtitleWelcome}>
          {t("sub_welcome")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("form_label_email")}
            name="email"
            autoComplete="email"
            autoFocus
            variant="outlined"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("form_label_password")}
            type="password"
            id="password"
            autoComplete="current-password"
            variant="outlined"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            /*className={classes.buttonMargin}*/
          >
            {t("form_button_enter")}
          </Button>
          <Link href="/forgot-password" variant="subtitle1">
              {t('label_link_forgot_password')}{' '}
          </Link>
        </Box>
        <Box component="span" m={2}>
          <Typography className={classes.versionText}>
            {t("title_description_version_frontend")} {versionFrontend}
          </Typography>
          <Typography className={classes.versionText}>
            {t("title_description_version_backend")} {versionBackend}
          </Typography>
        </Box>

      </Box>
    </Box>
  );
}
