import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useDefaultStyles } from "../../common/styles/default";

import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_scenario_banner.png";

import { useStyles } from "../styles/index.styles";
import { useScenarioStyles } from "../styles/scenarios.styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MAXLENGTH } from "../constants";
import { useModal } from "../../common/hooks/useModal";

import ThemesService from "../../services/ThemesService";
import IThemeList from "../../types/Themes";
import ICompetenceList from "../../types/Competence";

import CompetenceService from "../../services/CompetenceService";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SkillService from "../../services/SkillService";
import SheAiService from "../../services/SheAiService";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Form() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleOpenModal, dataModal } = useModal();

  const styles = useDefaultStyles();
  const classes = useStyles();
  const scenarioStyle = useScenarioStyles();

  const { id } = useParams();

  const handleCompetenceChange = (event: SelectChangeEvent) => {
    setCompetenceId(event.target.value);
  };

  const skillOptions = [
    "Habilidades Clínicas",
    "Procedimentos Práticos",
    "Investigação do Paciente",
    "Gestão de Pacientes",
    "Promoção da Saúde e Prevenção de Doenças",
    "Comunicação",
    "Tratamento de informações",
    "Compreensão das Ciências Básicas e Clínicas",
    "Atitudes e Ética",
    "Tomada de Decisão / Raciocínio Clínico",
    "Papel do médico",
    "Desenvolvimento pessoal",
  ];

  const [title, setTitle] = useState("");
  const [theme_id, setThemeId] = useState("");

  const [complexity, setComplexity] = useState("");
  const [environment, setEnvironment] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [goal_scene, setGoalScene] = useState("");

  const [competences, setCompetences] = useState<ICompetenceList[]>([]);
  const [competence_id, setCompetenceId] = useState("");
  const [skills, setSkills] = useState<any[]>([]);
  const [themes, setThemes] = useState<IThemeList[]>([]);
  const [skill_id, setSkillId] = useState("");
  const [performance, setPerformance] = useState("");
  const [actor, setActor] = useState("");
  const [material, setMaterial] = useState("");
  const [exame, setExame] = useState("");
  const [checklistItem, setChecklistItem] = useState("");
  const [selectedSkills, setSelectedSkills] = useState<any[]>([]);
  const [skills_ids, setSkillsIds] = useState<any[]>([]);

  const addTheme = () => {
    handleOpenModal({
      displayName: "ThemeModal",
      data: { loadThemes: loadThemes, checklist: 1 },
    });
  };

  const addCompetence = () => {
    handleOpenModal({
      displayName: "CompetenceModal",
      data: { loadCompetences: loadCompetences, checklist: 1 },
    });
  };

  const handleThemeChange = (event: SelectChangeEvent) => {
    setThemeId(event.target.value);
  };

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value);
  };

  const handleActorChange = (event: SelectChangeEvent) => {
    setActor(event.target.value);
  };

  const goBack = () => {
    navigate("/genhi/list");
  };

  const handleComplexityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setComplexity((event.target as HTMLInputElement).value);
  };

  const handleSexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSex((event.target as HTMLInputElement).value);
  };

  const handleSkillChange = (event: SelectChangeEvent) => {
    setSkillId(event.target.value);
  };

  const gdr = [
    {
      value: "M",
      label: t("title_dialog_male"),
    },
    {
      value: "F",
      label: t("title_dialog_female"),
    },
    {
      value: "O",
      label: t("title_dialog_others"),
    },
  ];

  const complex = [
    {
      value: "B",
      label: t("title_dialog_low_complexity"),
    },
    {
      value: "M",
      label: t("title_dialog_medium_complexity"),
    },
    {
      value: "A",
      label: t("title_dialog_high_complexity"),
    },
  ];

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    //setOpen(true);

    if (title == "") {
      toast(t("toast_msg_formscenaro_title"), {
        type: "error",
      });
      return;
    }

    if (theme_id == "") {
      toast(t("toast_msg_formscenaro_theme"), {
        type: "error",
      });
      return;
    }

    if (competence_id == "") {
      toast(t("toast_msg_formscenaro_competence"), {
        type: "error",
      });
      return;
    }

    if (skills_ids.length == 0) {
      toast(t("toast_msg_formscenaro_skill"), {
        type: "error",
      });
      return;
    }

    if (complexity == "") {
      toast(t("toast_msg_formscenaro_complexity"), {
        type: "error",
      });
      return;
    }

    if (sex == "") {
      toast(t("toast_msg_formscenaro_gender"), {
        type: "error",
      });
      return;
    }

    if (actor == "") {
      toast(t("toast_msg_formscenaro_actor"), {
        type: "error",
      });
      return;
    }

    if (age == "") {
      toast(t("toast_msg_formscenaro_age"), {
        type: "error",
      });
      return;
    }

    if (environment == "") {
      toast(t("toast_msg_formscenaro_environment"), {
        type: "error",
      });
      return;
    }

    if (material == "") {
      toast(t("toast_msg_formscenaro_material"), {
        type: "error",
      });
      return;
    }

    if (exame == "") {
      toast(t("toast_msg_formscenaro_exame"), {
        type: "error",
      });
      return;
    }

    if (checklistItem == "") {
      toast(t("toast_msg_formscenaro_checklistItem"), {
        type: "error",
      });
      return;
    }

    if (goal_scene == "") {
      toast(t("toast_msg_formscenaro_goal_scene"), {
        type: "error",
      });
      return;
    }

    if (performance == "") {
      toast(t("toast_msg_formscenaro_performance"), {
        type: "error",
      });
      return;
    }

    const data = {
      name: title,
      theme_id: theme_id,
      competence_id: competence_id,
      skills_id: skills_ids,
      complexity: complexity,
      actor: actor,
      actor_sex: sex,
      actor_age: age,
      environment: environment,
      performance: performance,
      user_instructions: goal_scene,
      number_materials: material,
      number_exames: exame,
      number_items_checklist: checklistItem,
    };

    SheAiService.background(data)
      .then((response: any) => {
        setOpen(true);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const handleMaterialChange = (event: SelectChangeEvent) => {
    setMaterial(event.target.value);
  };

  const handleExameChange = (event: SelectChangeEvent) => {
    setExame(event.target.value);
  };

  const handleChecklistItemChange = (event: SelectChangeEvent) => {
    setChecklistItem(event.target.value);
  };

  const envir = [
    {
      value: 1,
      label: t("title_enviroment_outpatient"),
    },
    {
      value: 2,
      label: t("title_enviroment_emergency_room"),
    },
    {
      value: 3,
      label: t("title_enviroment_impatient_unit"),
    },
    {
      value: 4,
      label: t("title_enviroment_intensive_care_unit"),
    },
    {
      value: 5,
      label: t("title_enviroment_surgical_center"),
    },
    {
      value: 6,
      label: t("title_enviroment_public_space"),
    },
  ];

  const materials = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const exames = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const checklists_itens = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id: any) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadThemes = async (page: number, paginate: number) => {
    await ThemesService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setThemes(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadSkills = async () => {
    await SkillService.getAll()
      .then((response: any) => {
        if (response) {
          setSkills(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadCompetences = async (page: number, paginate: number) => {
    await CompetenceService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setCompetences(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const handleSkillsChange = (event: any, newValue: any[]) => {
    const ids = newValue.map((skill) => skill.id);
    setSkillsIds(ids);
    setSelectedSkills(newValue);
  };

  useEffect(() => {
    loadThemes(1, 0);
    loadCompetences(1, 0);
    loadSkills();
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <AutoAwesomeOutlinedIcon sx={{ width: "3em", height: "3em" }} />
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              IA Generativa - GenHi
            </Typography>
          </Box>
        }
      />

      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box className={styles.containerForm}>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Box className={scenarioStyle.topField}>
                <TextField
                  required
                  fullWidth
                  id="title"
                  name="title"
                  label="Nome do Conjunto"
                  variant="outlined"
                  value={title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Itens pré requisitos
              </Typography>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>

          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("lbl_form_theme")}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={addTheme}
                className={scenarioStyle.importButton}
              >
                {" "}
                {t("btn_label_create_theme")}
              </Button>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b> Todo Cenário e Checklist
            e deve estar atrelado a uma competência. Selelecione abaixo a
            Competência desejada ou crie uma nova.
          </Typography>

          <Grid container spacing={2} sx={{ mb: 8 }}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="theme-label">Tema</InputLabel>
                <Select
                  labelId="theme-label"
                  id="theme_id"
                  label="Tema"
                  value={theme_id}
                  onChange={handleThemeChange}
                >
                  {themes.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("lbl_form_competence")}*
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={addCompetence}
                className={scenarioStyle.importButton}
              >
                {" "}
                {t("btn_label_create_competence")}
              </Button>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b> Todo Cenário e Checklist
            e deve estar atrelado a uma competência. Selelecione abaixo a
            Competência desejada ou crie uma nova.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="theme-label">Competência</InputLabel>
                <Select
                  labelId="theme-label"
                  id="competence_id"
                  label="Competência"
                  value={competence_id}
                  onChange={handleCompetenceChange}
                >
                  {competences.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Habilidades*
              </Typography>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b> A habilidade é um
            pré-requisito para gerar o background. Selecione abaixo a habilidade
            desejada ou crie uma nova.
          </Typography>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={skills}
                value={selectedSkills}
                onChange={handleSkillsChange}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      value={option.id}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Habilidades"
                    placeholder="Selecione as habilidades"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Configurações
              </Typography>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Box>
            <Grid container spacing={5} sx={{ mb: 10, mt: 3 }}>
              <Grid item xs={6}>
                <Box className={classes.checkBoxArea}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("input_label_complexity")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={complexity}
                      onChange={handleComplexityChange}
                    >
                      {complex.map((c, index) => (
                        <FormControlLabel
                          key={index}
                          value={c.value}
                          control={<Radio />}
                          label={c.label}
                          sx={{ mr: 10 }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.checkBoxArea}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("input_label_gender")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={sex}
                      onChange={handleSexChange}
                    >
                      {gdr.map((g, index) => (
                        <FormControlLabel
                          key={index}
                          value={g.value}
                          control={<Radio />}
                          label={g.label}
                          sx={{ mr: 10 }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      Ator
                    </FormLabel>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="theme-label">Ator</InputLabel>
                      <Select
                        labelId="theme-label"
                        id="actor"
                        label="Ator"
                        value={actor}
                        onChange={handleActorChange}
                      >
                        <MenuItem value="Pessoa Real">Pessoal Real</MenuItem>
                        <MenuItem value="Manequim">Manequim</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      Idade
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="age"
                      name="age"
                      variant="outlined"
                      value={age}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setAge(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("input_label_environment")}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        labelId="environment-label"
                        id="environment"
                        value={environment}
                        onChange={handleEnvironmentChange}
                      >
                        {envir.map((row, index) => (
                          <MenuItem value={row.value} key={index}>
                            {row.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={4}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      Número de materiais *
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        labelId="material-label"
                        id="material"
                        value={material}
                        onChange={handleMaterialChange}
                      >
                        {materials.map((row, index) => (
                          <MenuItem value={row} key={index}>
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      Número de exames *
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        labelId="exame-label"
                        id="exame"
                        value={exame}
                        onChange={handleExameChange}
                      >
                        {exames.map((row, index) => (
                          <MenuItem value={row} key={index}>
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: "bold" }}
                    >
                      Itens do checklist *
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        labelId="checklistItem-label"
                        id="checklistItem"
                        value={checklistItem}
                        onChange={handleChecklistItemChange}
                      >
                        {checklists_itens.map((row, index) => (
                          <MenuItem value={row} key={index}>
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={scenarioStyle.boxScenarioRegister}>
          <Box className={styles.containerForm}>
            <Grid container spacing={2} sx={{ mt: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Objetivo
                </Typography>
              </Grid>
            </Grid>
            <Box className={scenarioStyle.topBox}>
              <Divider className={scenarioStyle.divider} />
            </Box>
            <Box className={scenarioStyle.topField}>
              <Typography sx={{ mb: 2 }}>
                Instruções para a geração do enredo
              </Typography>
              <TextField
                required
                fullWidth
                id="performance"
                name="performance"
                variant="outlined"
                value={performance}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPerformance(event.target.value);
                }}
                multiline
                rows={5}
              />
            </Box>
            <Box className={scenarioStyle.topField}>
              <Typography sx={{ mb: 2 }}>
                Digite o objetivo do enredo de background
              </Typography>
              <TextField
                required
                fullWidth
                id="goal_scene"
                name="goal_scene"
                placeholder="Crie um contexto onde uma criança apresenta febre alta persistente, acompanhada de tosse e dificuldade para respirar. A mãe está preocupada e espera orientação sobre o tratamento adequado."
                variant="outlined"
                value={goal_scene}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGoalScene(event.target.value);
                }}
                multiline
                rows={5}
              />
            </Box>
          </Box>
        </Box>

        <Box className={styles.containerButton}>
          <Button type="submit" variant="contained" size="large">
            <AutoAwesomeOutlinedIcon sx={{ mr: 1 }} />
            Gerar Background
          </Button>
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            size="large"
            sx={{ ml: 3 }}
            onClick={goBack}
          >
            {" "}
            Voltar{" "}
          </Button>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Gerando background</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O cenário e checklist serão criados conforme especificações. Retorne
            para o histórico de itens para acompanhar o status de criação, esse
            processo pode levar alguns minutos, após esse processo é necessário
            validar os itens que foram criados. Somente após a sua validação o
            cenário ou checklist estarão disponível para uso.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goBack}>Retornar</Button>
          {/* <Button onClick={removeEntity} autoFocus>
            {t("btn_dialog_yes")}
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
