import api from '../config/api'
import IAuthData from '../types/Auths'

const login = (data: any) => {
  return api.post<IAuthData>('/login', data)
}

const logout = () => {
  return api.post<IAuthData>('/logout')
}

const version = () => {
  return api.get('/version')
}

const forgotPassword = (data: any) => {
  return api.post('/forgot-password', data)
}

const resetPassword = (data: any) => {
  return api.post('/reset-password', data)
}

const LoginService = {
  login,
  logout,
  version,
  forgotPassword,
  resetPassword,
}

export default LoginService
